@import "buttons";

footer {
  border-top: 1px solid #d9e1fb;
  background: #eff2f9;
  box-shadow: 0px -1px 0px 0px rgba(231, 236, 246, 0.004);
  padding: 70px 0 50px;
  font-size: 15px;

  p {
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 0;
    margin-top: 15px;

    &.left-text {
      margin-top: 20px;
    }
  }

  b {
    font-weight: 500;
    color: #000;
  }
}