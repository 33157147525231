@-webkit-keyframes rollIncustom {
  0% {
    opacity: 0;
    -webkit-transform: translateX(220%) translateY(220px);
    -ms-transform: translateX(220%) translateY(220px);
    transform: translateX(220%) translateY(220px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
@keyframes rollIncustom {
  0% {
    opacity: 0;
    -webkit-transform: translateX(220%) translateY(220px);
    -ms-transform: translateX(220%) translateY(220px);
    transform: translateX(220%) translateY(220px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
.rollIncustom {
  -webkit-animation-name: rollIncustom;
  animation-name: rollIncustom
}

@-webkit-keyframes rollIncustom1 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(150%) translateY(150px);
    -ms-transform: translateX(150%) translateY(150px);
    transform: translateX(150%) translateY(150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
@keyframes rollIncustom1 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(150%) translateY(150px);
    -ms-transform: translateX(150%) translateY(150px);
    transform: translateX(150%) translateY(150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
.rollIncustom1 {
  -webkit-animation-name: rollIncustom1;
  animation-name: rollIncustom1;
}

@-webkit-keyframes rollIncustom3 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px) translateY(350px);
    -ms-transform: translateX(-20px) translateY(350px);
    transform: translateX(-20px) translateY(350px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
@keyframes rollIncustom3 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px) translateY(350px);
    -ms-transform: translateX(-20px) translateY(350px);
    transform: translateX(-20px) translateY(350px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
.rollIncustom3 {
  -webkit-animation-name: rollIncustom3;
  animation-name: rollIncustom3;
}

@-webkit-keyframes rollIncustom4 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-90px) translateY(70px);
    -ms-transform: translateX(-90px) translateY(70px);
    transform: translateX(-90px) translateY(70px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
@keyframes rollIncustom4 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-90px) translateY(70px);
    -ms-transform: translateX(-90px) translateY(70px);
    transform: translateX(-90px) translateY(70px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
.rollIncustom4{
  -webkit-animation-name: rollIncustom4;
  animation-name: rollIncustom4;
}

@-webkit-keyframes rollIncustom5 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-500px) translateY(-50px);
    -ms-transform: translateX(-500px) translateY(-50px);
    transform: translateX(-500px) translateY(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
@keyframes rollIncustom5 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-500px) translateY(-50px);
    -ms-transform: translateX(-500px) translateY(-50px);
    transform: translateX(-500px) translateY(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
.rollIncustom5{
  -webkit-animation-name: rollIncustom5;
  animation-name: rollIncustom5;
}

@-webkit-keyframes rollIncustom6 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px) translateY(-150px);
    -ms-transform: translateX(30px) translateY(-150px);
    transform: translateX(30px) translateY(-150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
@keyframes rollIncustom6 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(30px) translateY(-150px);
    -ms-transform: translateX(30px) translateY(-150px);
    transform: translateX(30px) translateY(-150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
.rollIncustom6{
  -webkit-animation-name: rollIncustom6;
  animation-name: rollIncustom6;
}

@-webkit-keyframes rollIncustom7 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-150px) translateY(-150px);
    -ms-transform: translateX(-150px) translateY(-150px);
    transform: translateX(-150px) translateY(-150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
@keyframes rollIncustom7 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-150px) translateY(-150px);
    -ms-transform: translateX(-150px) translateY(-150px);
    transform: translateX(-150px) translateY(-150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
.rollIncustom7{
  -webkit-animation-name: rollIncustom7;
  animation-name: rollIncustom7;
}

@-webkit-keyframes rollIncustom8 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0px) translateY(-200px);
    -ms-transform: translateX(0px) translateY(-200px);
    transform: translateX(0px) translateY(-200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
@keyframes rollIncustom8 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(0px) translateY(-200px);
    -ms-transform: translateX(0px) translateY(-200px);
    transform: translateX(0px) translateY(-200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
.rollIncustom8{
  -webkit-animation-name: rollIncustom8;
  animation-name: rollIncustom8;
}

@-webkit-keyframes rollIncustom9 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200px) translateY(-150px);
    -ms-transform: translateX(200px) translateY(-150px);
    transform: translateX(200px) translateY(-150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
@keyframes rollIncustom9 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(500px) translateY(-150px);
    -ms-transform: translateX(200px) translateY(-150px);
    transform: translateX(200px) translateY(-150px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}
.rollIncustom9{
  -webkit-animation-name: rollIncustom9;
  animation-name: rollIncustom9;
}