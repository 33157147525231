@import "buttons";

.site-header {
  background-image: -webkit-linear-gradient(140deg, #00396b 0%, #0080be 100%);
  padding: 15px 0 100px;
  position: relative;

  .top-nav {
    overflow: hidden;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: space-between;

    #header-logo {
      text-align: left;
      padding: 0 0 20px;

      img {
        height: 24px;
        margin: 9px 0;
      }
    }

    #header-contact {
      text-align: right;
      padding: 10px 0;

      a.btn-contacts {
        background: transparent;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        min-width: 120px;
        text-align: center;
        padding: 10px 0 0;
        //border-radius: 45px;
        //box-shadow: none;
        //transition-property: color;
        //transition: all 0.3s ease;
        //
        //&:hover {
        //  color: #fff;
        //  background: $color-button-hover;
        //  border-color: $color-button-hover;
        //  box-shadow: none;
        //}
      }
    }
  }

  .btm-border {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    path {
      fill: $background-color;
    }
  }
}