// fonts
$font-family: 'Poppins', Helvetica, sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-heavy: 700;
$font-height: 1;
$font-size: 12px;

// colors
$color-text: #677294;
$color-btn: #0076bb;

$color-button-hover: blue;
$background-color: #fbfbfd;

// Placeholder Mixins
@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}