@import "variables";

.btn {
  text-decoration: none;
  outline: none;

  font: 500 14px $font-family;
  color: #fff;
  background: $color-btn;
  box-shadow: 0 20px 30px 0 rgba(12, 0, 46, 0.1);
  border-radius: 3px;
  padding: 16px 38px;
  border: 1px solid $color-btn;
  transition: all 0.2s linear;
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: $color-btn;
    box-shadow: none;
    background: transparent;
  }
}