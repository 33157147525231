@import "~light-modal/dist/css/light-modal.css";
@import "variables";

#contacts {

  .hidden {
    display: none !important;
  }

  .g-recaptcha {
    margin-bottom: 20px;
  }


  .light-modal-body {
    max-height: 750px;
  }

  .light-modal-content {
    background-color: $background-color;

    h5 {
      color: #000;
      margin: 5px 0 20px;
      font-weight: 600;
    }
  }

  .light-modal-close-icon, .light-modal-close-btn {
    background-color: #777;
    border-radius: 50%;
    padding: 4px 5px 1px 6px;
    text-align: center;
    &:hover {
      background-color: $color-btn;
      color: #FFF;
    }
  }

  .text_box {
    margin-bottom: 20px;

    &.required {
      label {
        font-weight: 500;
        color: darken($color-text, 20%);
        &:after {
          content: "*"
        }
      }
    }

    span {
      font-size: 12px;
      color: $color-text;
    }

    label {
      color: $color-text;
      font-size: 14px;
    }

    input, textarea {
      font: 300 15px $font-family;
      color: #000;
      height: 35px;
      border-radius: 4px;
      background-color: rgb(255, 255, 255);
      box-shadow: 0 2px 2px 0 rgba(12, 0, 46, 0.12);
      width: 100%;
      border: 1px solid #fff;
      padding-left: 15px;
      @include placeholder {
        color: #98b7cd;
      }
      &:focus {
        border-color: rgb(242, 239, 252);
        box-shadow: 0 2px 2px 0 rgba(12, 0, 46, 0.15);
        outline: none;
      }
    }
    textarea {
      height: 80px;
      padding: 15px;
    }
  }

  .btn {
    width: 100%;
  }

  //Loader-----
  .lds-dual-ring {
    display: inline-block;
    width: 20px;
    height: 20px;
  }

  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 2px 0 0 0;
    border-radius: 50%;
    border: 5px solid $color-btn;
    border-color: $color-btn transparent $color-btn transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}