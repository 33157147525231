@import "../variables";
@import "../animate";

#page-index {

  .hero-area {
    .hero-text {
      h2 {
        line-height: 1.2;
        color: white;
        font-weight: 500;
        font-size: 50px;

        visibility: visible;
        animation-delay: 0.2s;
        animation-name: fadeInLeft;

        margin-top: 50px;

        span {
          color: #FFF;
          line-height: 30px;
          font-size: 50px;
          font-weight: 700;
        }
      }

      p {
        margin-top: 30px;
        color: #FFF;
        line-height: 30px;
        font-size: 18px;

        visibility: visible;
        animation-delay: 0.4s;
        animation-name: fadeInLeft;
      }
    }

    .cubes {
      position: relative;
      img {
        position: absolute;
        right: -20px;
        top: -20px;
        height: 450px;
      }
    }

  }

  .agency_featured_area {
    padding-bottom: 80px;

    h2 {
      text-align: center;
      margin-top: 50px;
      color: #000;
    }

    .features_info {
      margin-top: -50px;

      position: relative;
      padding-bottom: 170px;

      .dot_img {
        position: absolute;
        left: 0;
        top: 28px;
      }

      &.feature_info_two {
        padding-bottom: 70px;
      }

      .dot {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: rgba(255, 161, 39, 0.161);
        display: block;
        position: absolute;
        left: -9px;
        top: 15px;
        .dot1 {
          position: absolute;
          left: 50%;
          margin-top: -4px;
          margin-left: -4px;
          top: 50%;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #feb85d;
        }
        .dot2 {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgba(254, 184, 93, 0.8);
          animation: pulsate 3s infinite;
          animation-delay: 1.5s;
          transform: translate(-50%, -50%);
          will-change: transform;
        }
        &.middle_dot {
          left: 54.8%;
          transform: translateX(-50%);
          bottom: -8px;
          top: auto;
        }
      }
    }

    .hero {
      max-height: 370px;
      padding: 80px 0 0 0;
      fill: #007fb7;
    }

    .agency_featured_item {
      margin-top: 130px;

      .agency_featured_content {
        position: relative;
        h3 {
          font: 500 26px/36px $font-family;
          color: #222d39;
          margin: 32px 0 25px;
        }
        p {
          font-size: 15px;
        }
        .icon {
          width: 46px;
          height: 46px;
          line-height: 46px;
          border-radius: 50%;
          background-image: -moz-linear-gradient(40deg, rgb(94, 44, 237) 0%, rgb(164, 133, 253) 100%);
          background-image: -webkit-linear-gradient(40deg, rgb(94, 44, 237) 0%, rgb(164, 133, 253) 100%);
          background-image: -ms-linear-gradient(40deg, rgb(94, 44, 237) 0%, rgb(164, 133, 253) 100%);
          box-shadow: 0 10px 20px 0 rgba(94, 44, 237, 0.2);
          font-size: 20px;
          color: #fff;
          display: inline-block;
          text-align: center;
        }
      }
      &.agency_featured_item_two {
        .agency_featured_content {
          .dot {
            left: 30px;
          }
        }
      }
    }

    .agency_banner_btn {
      font: 500 14px $font-family;
      color: #fff;
      background: $color-btn;
      box-shadow: 0 20px 30px 0 rgba(12, 0, 46, 0.1);
      border-radius: 3px;
      padding: 16px 38px;
      border: 1px solid $color-btn;
      transition: all 0.2s linear;
      cursor: pointer;
      display: inline-block;

      &:hover {
        color: $color-btn;
        box-shadow: none;
        background: transparent;
      }
    }
  }

  .software_promo_area {
    background: #FFF;
    overflow: hidden;

    h2 {
      color: #000;
    }

    p {
      font-size: 18px;
      line-height: 34px;
      margin-bottom: 80px;
    }

    .s_promo_info {
      .promo_item {
        position: absolute;
        border: 1px solid rgba(93, 88, 247, 0.14);
        border-radius: 50%;
        background-color: rgb(255, 255, 255);
        box-shadow: 0 30px 60px 0 rgba(0, 11, 40, 0.1);
        display: flex;
        align-items: center;
        text-align: center;
        opacity: 0;

        img {
          max-width: 99px;
        }

        .text {
          width: 100%;
          p {
            display: block;
            font-size: 14px;
            color: #677294;
            margin-bottom: 0;
            margin-top: 5px;
            font-weight: 400;
          }
        }

        &.item_one {
          width: 150px;
          height: 150px;
          left: 50%;
          top: 50px;
        }
        &.item_two {
          width: 140px;
          height: 140px;
          left: -5px;
          top: 275px;
        }
        &.item_three {
          width: 120px;
          height: 120px;
          left: 290px;
          top: 375px;
        }
        &.item_four {
          width: 160px;
          height: 160px;
          left: 67%;
          top: 38%;
        }
        &.item_five {
          width: 160px;
          height: 160px;
          left: 93%;
          top: 55%;
        }
        &.item_six {
          width: 80px;
          height: 80px;
          top: 69%;
          left: 38%;
        }
        &.item_seven {
          width: 100px;
          height: 100px;
          top: 70%;
          left: 67%;
        }
        &.item_eight {
          width: 140px;
          height: 140px;
          top: 84%;
          left: 41%;
        }
        &.item_nine {
          width: 160px;
          height: 160px;
          left: 110px;
          top: 65%;
        }

        &.scroll_animation {
          &.in-view {
            opacity: 1;
            &.item_one {
              animation: rollIncustom3 0.6s linear;
            }
            &.item_two {
              animation: rollIncustom 0.6s linear;
            }
            &.item_three {
              animation: rollIncustom1 0.9s linear;
            }
            &.item_four {
              animation: rollIncustom4 1.2s linear;
            }
            &.item_five {
              animation: rollIncustom5 0.9s linear;
            }
            &.item_six {
              animation: rollIncustom6 0.6s linear;
              img {
                width: 60px;
              }
            }
            &.item_seven {
              animation: rollIncustom7 0.9s linear;
              img {
                width: 70px;
              }
            }
            &.item_eight {
              animation: rollIncustom8 0.6s linear;
            }
            &.item_nine {
              animation: rollIncustom9 0.9s linear;
            }
          }
        }
      }
    }

    .round_shape {
      border: 1px solid #edf0f4;
      border-radius: 50%;
      max-width: 1150px;
      min-height: 1150px;
      margin: 0 auto;
      position: relative;

      .r_shape {
        position: absolute;
        top: 50%;
        left: 50%;
        border: 1px solid #edf0f4;
        border-radius: 50%;
        transform: translate(-50%, -50%);

        &.farecloud {
          img {
            width: 50px;
          }
        }

        &.r_shape_one {
          height: 920px;
          width: 920px;
        }
        &.r_shape_two {
          height: 735px;
          width: 735px;
        }
        &.r_shape_three {
          height: 550px;
          width: 550px;
        }
        &.r_shape_four {
          height: 370px;
          width: 370px;
        }
        &.r_shape_five {
          height: 185px;
          width: 185px;
          background-image: -moz-linear-gradient(40deg, rgb(103, 84, 226) 0%, rgb(25, 204, 230) 100%);
          background-image: -webkit-linear-gradient(40deg, rgb(103, 84, 226) 0%, rgb(25, 204, 230) 100%);
          background-image: -ms-linear-gradient(40deg, rgb(103, 84, 226) 0%, rgb(25, 204, 230) 100%);
          text-align: center;
          display: flex;
          align-items: center;
          .text {
            width: 100%;
          }
          a {
            display: block;
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            margin-top: 12px;
          }
        }
      }
    }
  }

  ////// Experts

  .experts_team_area {
    padding: 120px 0 50px 0;
  }

  .ex_team_item {
    position: relative;
    text-align: center;
    margin-bottom: 40px;
    overflow: hidden;

    img {
      max-width: 100%;
    }
    .hover_content, .team_content {
      position: absolute;
      width: 100%;
    }
    h3 {
      margin-bottom: 3px;
    }
    h5 {
      font: 300 14px/24px $font-family;
      color: #677294;
      margin-bottom: 0;
    }
    .team_content {
      bottom: 0;
      background: rgba(255, 255, 255, 0.90);
      padding: 17px 0;
      transition: all 0.4s ease;
    }
  }

  /////// Contacts
  .action_contacts {
    background-image: -webkit-linear-gradient(140deg, #00396b 0%, #0080be 100%);
    padding: 100px 0 60px 0;
    position: relative;
    overflow: hidden;

    .curved {
      position: absolute;
      top: -2px;
      width: 100%;
      height: 50px;
    }

    h2 {
      color: white;
    }

    .action_content {
      h2 {
        color: #fff;
        margin-bottom: 30px;
      }

      .about_btn {
        font: 500 14px $font-family;
        padding: 17px 28px;
        box-shadow: 0 20px 30px 0 rgba(12, 0, 46, 0.1);
        border-radius: 3px;
        display: inline-block;
        transition: all 0.3s ease;
        background: transparent;
        border: 1px solid #fff;
        color: #fff;
        &:hover {
          box-shadow: none;
          color: $color-btn;
          background: #fff;
        }
      }

      .white_btn {
        color: $color-btn;
        box-shadow: none;
        background: #fff;
        margin-right: 25px;
        &:hover {
          box-shadow: 0 20px 30px 0 rgba(12, 0, 46, 0.1);
          color: #fff;
          background: $color-btn;
        }
      }
    }
  }

}